import { gsap } from "gsap";
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(DrawSVGPlugin, TextPlugin);

const GSAP_ELEMENT_1 = '#bovenhoek';
const GSAP_ELEMENT_2 = '#onderhoek';

function ekelmans__load_header() {
  
  gsap.set([GSAP_ELEMENT_1, GSAP_ELEMENT_2], {
    visibility: "visible"
  });

  gsap.to("#onderhoek", {
    drawSVG: true,
    duration: 1.5,
    ease: "power1.inOut",
    delay: 0.8,
  });

  gsap.to("#bovenhoek", {
    drawSVG: true,
    duration: 1.5,
    delay: 0.4,
    ease: "power1.inOut"
  });

  gsap.fromTo("#title", {
    duration: 0.75,
    ease: "power1.inOut",
    opacity: 0,
    y: 20,
  }, {
    duration: 0.75,
    opacity: 1,
    ease: "power1.inOut",
    y: 0,
  });
  gsap.fromTo("#subtitle", {
    duration: 0.75,
    ease: "power1.inOut",
    y: -30,
    opacity: 0,
  }, {
    duration: 0.75,
    opacity: 1,
    ease: "power1.inOut",
    y: 0,
  });
}


const EL_1 = document.querySelector(GSAP_ELEMENT_1);
const EL_2 = document.querySelector(GSAP_ELEMENT_2);

if (EL_1 && EL_2) {
  if (window.addEventListener) {
    addEventListener('DOMContentLoaded', ekelmans__load_header, false);
    addEventListener('load', ekelmans__load_header, false);
  } else if (window.attachEvent) {
    attachEvent('onDOMContentLoaded', ekelmans__load_header); // Internet Explorer 9+ :(
    attachEvent('onload', ekelmans__load_header);
  }
}
