import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

const SCROLLTRIGGER_EL = '.services';
const GSAP_ELEMENT_1 = '.services__usps li';
const DURATION = .4;

function ekelmans__load_list_items() {

  let tl = new gsap.timeline({
    scrollTrigger: {
      trigger: GSAP_ELEMENT_1
    }
  });
  tl.add("stagger", "+=0.5");

  tl.staggerFrom(
    GSAP_ELEMENT_1, 
    DURATION, 
    { opacity: 0, x: -50 }, 
    .1, 
    "stagger"
  )
  
}


const EL_1 = document.querySelector(GSAP_ELEMENT_1);

if (EL_1 ) {
  ekelmans__load_list_items();
}
