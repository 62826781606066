import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin);


const scrollToBtn = document.getElementById('#scrollToMain');
if (scrollToBtn) {
  scrollToBtn.addEventListener('click', (e) => { 
    e.preventDefault();
    gsap.to(window, {
      duration: 0.3, 
      scrollTo: "#main",
      ease: 'power1.inOut'
    });
  }); 
}